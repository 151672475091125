import { useEffect, useMemo, useState } from 'react';
import PencilFill from 'remixicon-react/PencilFillIcon';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import EyeLineIcon from 'remixicon-react/EyeLineIcon';
import EyeOffLineIcon from 'remixicon-react/EyeOffLineIcon';
import IndeterminateCircleFillIcon from 'remixicon-react/IndeterminateCircleFillIcon';
import EyeFill from 'remixicon-react/EyeFillIcon';
import Lock2Fill from 'remixicon-react/Lock2FillIcon';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import Tag from '../../components/utils/Tag'
import { Link } from 'react-router-dom';
import moment from "moment";
import ReactCountryFlag from 'react-country-flag';
import PhoneInput from 'react-phone-number-input';
import CardLayout from '../../components/utils/CardLayout';
import Table from '../../components/utils/Table';
import SideButtons from '../../components/utils/SideButtons';
import SearchBar from '../../components/utils/SearchBar';
import dealerAction from '../../redux/dealer/actions'
import countryAction from '../../redux/country/actions'
import Modal from '../../components/utils/Model';
import Popconfirm from '../../components/utils/Popover';
import Alert from '../../components/utils/Alert';
import Button from '../../components/utils/Button';
import DealerViewModel from '../../components/utils/DealerViewModel';
import { PASSWORD_VALIDATE, EMAIL_PATTERN } from '../../helpers/constant';
import { convertDateToRequiredFormatTZ } from '../../helpers/commonFunction';
import { isValidPhoneNumber } from 'libphonenumber-js';
import 'react-phone-number-input/style.css'



function Dealer() {
    const { getDealer, resetDealer, addDealer, getEmailDomains, getDealerAllowCreation, editDealer, deleteDealer, prepareDealerForm, getDealerLevel, resetDealerMessage, preparePasswordForm, dealerPasswordReset } = dealerAction;
    const { getCountry } = countryAction;

    const dispatch = useDispatch()
    const loading = useSelector((state) => state.dealer.loading);
    const { emailDomainsList } = useSelector((state) => state.dealer);
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const dealerLevelResult = useSelector((state) => state.dealer.dealerLevelResult);
    const dealerCreationResult = useSelector((state) => state.dealer.dealerCreationResult);
    const dealerTariffResult = useSelector((state) => state.dealer.dealerTariffResult);
    const successMessage = useSelector((state) => state.dealer.dealerMessage);
    const errorMessage = useSelector((state) => state.dealer.dealerError);
    const buttonLoading = useSelector((state) => state.dealer.buttonLoading);
    const dealerFormModal = useSelector((state) => state.dealer.dealerFormModal);
    const showAlertMessage = useSelector((state) => state.dealer.showAlertMessage)
    const countryResult = useSelector((state) => state.country.countryResult);
    const tariffResult = useState([])
    const { userDetails, tokenData } = useSelector((state) => state.auth);
    const [filterText, setFilterText] = useState("");
    const [formTitle, setFormTitle] = useState('');
    const [formButton, setFormButton] = useState('');
    const [editFlag, setEditFlag] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [editID, seteditID] = useState(null);
    const [selectedDealerDetails, setselectedDealerDetails] = useState([])
    const dealerPasswordModal = useSelector((state) => state.dealer.dealerPasswordModal);
    const [dealerData, setDealerData] = useState({
        allowSellerCreation: 0,
        EmailForwarding: 1
    })
    const [dealerCreatetionFlag, setDealerCreatetionFlag] = useState();
    const { register, handleSubmit, formState: { errors }, watch, reset, control, trigger, setValue } = useForm({
        defaultValues: dealerData,
    });
    const [passwordData, setPasswordData] = useState({
        newPassword: "",
        userId: null
    })
    const { register: registerReset, handleSubmit: handleSubmitReset, watch: watchReset, formState: { errors: errorsReset }, reset: resetReset
    } = useForm({ defaultValues: passwordData });
    const [passwordShow, setPasswordShow] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false)
    const [responseData, setResponseData] = useState([])
    const [deleteItemId, setDeleteItemId] = useState()
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const newPassword = watchReset('newPassword', '');

    const [phoneValue, setPhoneValue] = useState("");
    const [countryCode, setCountryCode] = useState("US");


    useEffect(() => {
        dispatch(getDealer());
        dispatch(getCountry());
        dispatch(getDealerLevel());
        dispatch(getEmailDomains());
    }, [])

    useEffect(() => {
        if (successMessage !== null && successMessage !== undefined) {
            displayAlert(successMessage, 'alert-success');
            reset(dealerData);
        }
    }, [successMessage]);

    useEffect(() => {
        if (errorMessage !== null && errorMessage !== undefined) {
            displayAlertModal(errorMessage, 'alert-danger');
        }
    }, [errorMessage]);

    useEffect(() => {
        reset(dealerData);
        resetReset(passwordData);
    }, [dealerData, passwordData]);

    useEffect(() => {
        if (dealerCreationResult === 1) {
            setDealerCreatetionFlag(false)
        } else if (dealerCreationResult === 0) {
            setDealerCreatetionFlag(true)
        }

    }, [dealerCreationResult]);

    const handleShowDealerDetails = (item) => {
        setResponseData(item)
        setShowViewModal(true);

    }

    const responseItems = responseData ? () => {
        const dealerData = [
            [
                { name: "Dealer Name", value: responseData.DealerName },
                { name: "Allow Dealer Creation", value: responseData.AllowSellerCreation === 1 ? 'Yes' : 'No' },
                { name: "Email ID", value: responseData.ContactEmailID },
                { name: "Creation Date", value: moment(responseData.CreatedDtTm).format(`${process.env.REACT_APP_DATE_FORMAT}`) }
            ],
            [
                { name: 'Other Details' }
            ],
            [
                { name: "Dealer Name", value: responseData.DealerName },
                { name: "Username", value: responseData.Username },
                { name: " Email ID", value: responseData.ContactEmailID },
                { name: "Number", value: responseData.ContactNumber },
                { name: "Contact Person", value: responseData.ContactPerson },
                { name: "Account Balance", value: `$ ${responseData.AccountBalance}` },
                { name: "Creation Date", value: moment(responseData.CreatedDtTm).format(`${process.env.REACT_APP_DATE_FORMAT}`) },
                { name: "Verified Date", value: moment(responseData.verifiedDate).format(`${process.env.REACT_APP_DATE_FORMAT}`) },
                { name: "Address", value: responseData.Address ? responseData.Address : "N/A" },
                { name: "State", value: responseData.State ? responseData.State : "N/A" },
                { name: "Country", value: responseData.CountryName ? responseData.CountryName : "N/A" },
                { name: "Postal Code", value: responseData.ZipCode ? responseData.ZipCode : "N/A" },
                { name: "Dealer Creation", value: <Tag color={`${responseData.AllowSellerCreation === 1 ? "badge-success" : "badge-danger"}`} title={responseData.AllowSellerCreation === 1 ? "Yes" : "No"} /> },
                { name: "Status", value: <Tag color={`${responseData.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={responseData.IsActive === 1 ? "Active" : "Inactive"} /> },
                { name: "Verified", value: <Tag color={`${responseData.IsVerified === 1 ? "badge-success" : "badge-danger"}`} title={responseData.IsVerified === 1 ? "Yes" : "No"} /> },

            ],

        ]
        return dealerData;
    } : [];

    const dealerItem = responseItems()

    const handleCancelmodal = () => {
        setShowViewModal(false)
    }


    const columns = [
        { displayName: '#', key: 'key' },
        { displayName: 'DEALER NAME', key: 'dealerName' },
        { displayName: 'USERNAME', key: 'username' },
        { displayName: 'EMAIL', key: 'contactEmailId' },
        { displayName: 'COUNTRY', key: 'country' },
        { displayName: 'ACCOUNT BALANCE', key: 'accountBalance' },
        { displayName: 'CREATE DATE', key: 'createDate' },
        { displayName: 'VERIFY DATE', key: 'verifiedDate' },
        { displayName: 'STATUS', key: 'status' },
        { displayName: 'ACTIONS', key: 'action' },
    ];

    const filteredItems = Array.isArray(dealerResult) && dealerResult.length > 0 ? dealerResult.filter(item => {
        if (typeof filterText !== 'string' || typeof item !== 'object') return false;
        // Check if any value in the object contains the filterText
        return Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
    }) : [];

    const data = filteredItems && filteredItems.length > 0 ? filteredItems.map((dealer, key) => {
        var address = '';
        if (dealer.Address != null && dealer.Address != '')
            address += dealer.Address + ', ';
        if (dealer.State != null && dealer.State != '')
            address += dealer.State + ', ';
        if (dealer.ZipCode != null && dealer.ZipCode != '')
            address += dealer.ZipCode + ', ';
        if ((dealer.ParentID && dealer.ParentID !== 0)) {
            var actionContent = (
                <>
                    {/* {userDetails && userDetails.length > 0 && userDetails[0] && userDetails[0].DealerID === dealer.ParentID && !isDealerAgent(tokenData) && !isAdminAgent(tokenData) ?
                        <Link to="#">
                            <PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleOpenEditFormModal(dealer.ID)} />
                        </Link> 
                        :

                        <IndeterminateCircleFillIcon size="1.2em" style={{ color: "#888b8e", marginRight: "3px", cursor: "not-allowed" }} />
                    } */}
                    <Link to="#">
                        <span data-toggle="tooltip" data-placement="top" title="Edit">
                            <PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleOpenEditFormModal(dealer.ID)} />
                        </span>
                    </Link>


                    {buttonLoading && deleteItemId === dealer.ID ? <span className="spinner-border spinner-border-sm" role="status"></span> : dealer.IsActive === 1 ? // && !isDealerAgent(tokenData) && !isAdminAgent(tokenData)
                        <Popconfirm
                            title="Are you sure to inactive this dealer?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDelete(dealer.ID, 0)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Inactive">
                                    <DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>

                        : <Popconfirm
                            title="Are you sure to active this dealer?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDelete(dealer.ID, 1)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Active">
                                    <CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>}

                    <Link to="#"
                        onClick={() => handleShowDealerDetails(dealer)}
                    >
                        <span data-toggle="tooltip" data-placement="top" title="View">
                            <EyeFill size="1.2em" style={{ color: "#294c28eb" }} />
                        </span>
                    </Link>
                    <Link to="#"
                        onClick={() => handleOpenPasswordFormModal(dealer.userId)}
                    >
                        <span data-toggle="tooltip" data-placement="top" title="Reset password">
                            <Lock2Fill size="1.2em" style={{ color: "#294c28eb" }} />
                        </span>
                    </Link>
                </>
            );
        } else {
            actionContent = '';
        }
        return {
            key: key + 1,
            dealerName: dealer.DealerName,
            username: dealer.Username,
            contactEmailId: dealer.ContactEmailID,
            country: <Tag color="badge-primary" title={dealer.CountryName} />,
            createDate: dealer.CreatedDtTm !== null ? convertDateToRequiredFormatTZ(dealer.CreatedDtTm) : '',
            verifiedDate: dealer.VerifiedDate !== null ? convertDateToRequiredFormatTZ(dealer.VerifiedDate) : '',
            status: <Tag color={`${dealer.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={dealer.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: actionContent,
            accountBalance: dealer.AccountBalance !== null ? dealer.AccountBalance < 0 ? `-$${Math.abs(dealer.AccountBalance)}` : `$${dealer.AccountBalance}` : `£0.00`
        }
    }) : [];

    const handleOpenEditFormModal = (dealerId) => {
        dispatch(prepareDealerForm());
        dispatch(getDealerAllowCreation(dealerId));
        setFormTitle('Edit Dealer');
        setFormButton('Update');
        setEditFlag(true);
        seteditID(dealerId);
        let selectedDealer = dealerResult && dealerResult.filter(dealer => dealer.ID === dealerId);
        setselectedDealerDetails(selectedDealer);
        setDealerData(prevState => ({
            ...prevState,
            EmailForwarding: selectedDealer[0].EmailForwarding == 0 ? '' : selectedDealer[0].EmailForwarding,
            dealerName: selectedDealer[0].DealerName,
            contactPerson: selectedDealer[0].ContactPerson,
            contactNumber: selectedDealer[0].ContactNumber,
            contactEmailID: selectedDealer[0].ContactEmailID,
            address: selectedDealer[0].Address,
            countryID: selectedDealer[0].CountryID,
            zipCode: selectedDealer[0].ZipCode,
            state: selectedDealer[0].State,
            city: selectedDealer[0].City,
            tariffGroupID: selectedDealer[0].TariffGroupID,
            minimumTopUpAmount: selectedDealer[0].MinimumTopUpAmount,
            maximumTopUpAmount: selectedDealer[0].MaximumTopUpAmount,
            paypalTransactionFee: selectedDealer[0].PaypalTransactionFee,
            allowSellerCreation: selectedDealer[0].AllowSellerCreation,
            allowTariffCreation: selectedDealer[0].AllowTariffCreation,
        }));
        setPhoneValue(selectedDealer[0].ContactNumber);
    }

    const handleCancel = () => {
        reset(dealerData);
        setDealerData(prevState => ({
            ...prevState,
            username: "",
            password: "",
            dealerName: "",
            contactPerson: "",
            contactNumber: "",
            contactEmailID: "",
            address: "",
            countryID: "",
            zipCode: "",
            state: "",
            city: "",
            tariffGroupID: "",
            minimumTopUpAmount: 0,
            maximumTopUpAmount: 0,
            paypalTransactionFee: 0,
            allowSellerCreation: 0,
            allowTariffCreation: 0,
        }));
        setPhoneValue("");
        setPasswordData(prevState => ({
            ...prevState,
            newPassword: "",
            confirmNewPassword: "",
            userId: null
        }));
        dispatch(resetDealer());
        setEditFlag(false)
    }

    const handleOpenAddFormModal = () => {
        dispatch(prepareDealerForm());
        setFormTitle('Add Dealer');
        setFormButton('Submit');
        setEditFlag(false);
        setDealerData(prevState => ({
            ...prevState,
            dealerName: "",
            contactPerson: "",
            contactNumber: "",
            contactEmailID: "",
            address: "",
            countryID: "",
            zipCode: "",
            state: "",
            city: "",
            tariffGroupID: "",
            minimumTopUpAmount: "",
            maximumTopUpAmount: "",
            paypalTransactionFee: "",
            allowSellerCreation: "",
            allowTariffCreation: "",
        }));
    }

    const onSubmit = (data) => {
        data.address = data.address ? data.address : '';
        data.city = data.city ? data.city : '';
        data.state = data.state ? data.state : '';
        data.zipCode = data.zipCode ? data.zipCode : '';
        const newData = {
            ...data,
            allowSellerCreation: data.allowSellerCreation ? 1 : 0,
            allowTariffCreation: data.allowTariffCreation ? 1 : 0,
        };
        dispatch(addDealer(newData));
    };

    const handleDelete = (ID, flag) => {
        setDeleteItemId(ID)
        dispatch(deleteDealer(ID, flag));
    }

    const handleUpdate = (data) => {
        const newData = {
            ...data,
            allowSellerCreation: data.allowSellerCreation ? 1 : 0,
            allowTariffCreation: data.allowTariffCreation ? 1 : 0,
        };
        dispatch(editDealer(editID, newData))
    }

    const handleFilterChange = (value) => {
        setFilterText(value);
    };

    const displayAlertModal = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlertModal(true);
        dispatch(resetDealerMessage());
    };

    const displayAlert = (text, type) => {
        setShowAlertModal(false);
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetDealerMessage());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setShowAlertModal(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetDealerMessage());
    }

    const handleOpenPasswordFormModal = (userId) => {
        setFormButton("Reset Password")
        dispatch(preparePasswordForm());
        setConfirmPasswordShow(false);
        setPasswordShow(false);
        setPasswordData(prevState => ({
            ...prevState,
            newPassword: "",
            confirmNewPassword: "",
            userId: userId
        }));
    }

    const handleResetPassword = (data) => {
        dispatch(dealerPasswordReset(data))
    };

    const validatePhoneNumber = (value) => {
        if (!isValidPhoneNumber(value, countryCode)) {
            return "Invalid phone number for the selected country";
        }
        return true;
    };

    const handlePhoneChange = (value) => {
        setPhoneValue(value);
        setValue('contactNumber', value);
        trigger('contactNumber');
    };

    const handleCountryChange = (country) => {
        setCountryCode(country)
    };


    return (
        <CardLayout title="Dealers">
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <div className="row align-items-baseline">
                <div className='col-lg-6 col-md-6  od-2'>
                    <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                </div>
                <div className='col-lg-6 col-md-6  text-right'>
                    {dealerLevelResult && dealerLevelResult.length > 0 && dealerLevelResult.map((data) => {
                        return (data.level <= 2 && data.AllowSellerCreation === 1) && <SideButtons title="Add Dealer" buttonStyle="primary" onClick={handleOpenAddFormModal} /> // && !isDealerAgent(tokenData) && !isAdminAgent(tokenData)
                    })}</div>

            </div>
            <Table columns={columns} loading={loading} data={data} pagination={true} />
            <Modal
                showModal={dealerFormModal}
                title={formTitle}
                onHide={handleCancel}
                width={700}
                showFooter={false}
            >
                {showAlertModal && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
                <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
                    <div className="form-row">
                        {/* <div className="col-md-6 mb-3">
                            <label>Dealer Name</label>
                            <input type="text" placeholder="Enter Dealer Name" className="form-control"
                                name="dealerName" {...register("dealerName", {
                                    required: true,
                                })} />
                            {errors.dealerName?.type === "required" && (
                                <div className="invalid-feedback">Dealer name is required !</div>

                            )}
                        </div> */}

                        <div className="col-md-6 mb-3">
                            <label>Dealer Name</label>
                            <input
                                type="text"
                                placeholder="Enter Dealer Name"
                                className={"form-control"}
                                name="dealerName"
                                {...register("dealerName", {
                                    required: "Dealer name is required!",
                                    validate: (value) =>
                                        /^[A-Za-z][A-Za-z0-9\s!@#$%^&*(),.?":{}|<>]*$/.test(value) ||
                                        "Dealer name is invalid!",
                                })}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^A-Za-z0-9\s!@#$%^&*(),.?":{}|<>]/g, "");
                                }}
                            />
                            {errors.dealerName && (
                                <div className="invalid-feedback">{errors.dealerName.message}</div>
                            )}
                        </div>

                        <div className="col-md-6 mb-3">
                            <label>Contact Person</label>
                            <input type="text" className="form-control" placeholder="Enter Contact Person" name="contactPerson" {...register("contactPerson", {
                                required: true,
                            })} />
                            {errors.contactPerson?.type === "required" && (
                                <div className="invalid-feedback">Contact person is required !</div>
                            )}
                        </div>
                        {/* <div className="col-md-6 mb-3">
                            <label>Contact Number</label>
                            <input type="number" className="form-control" placeholder="Enter Contact Number" name="contactNumber" {...register("contactNumber", {
                                required: true,
                                minLength: 10,
                            })} /> */}
                        {/* <Controller
                                name="contactNumber"
                                control={control}
                                rules={{
                                    required: true,
                                    minLength: 10,
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <PhoneInput
                                        value={value}
                                        onChange={onChange}
                                        defaultCountry="US"
                                        international
                                        className="form-control"
                                        countryCallingCodeEditable={false}
                                        id="contactNumber"
                                        placeholder="Enter phone number"
                                    />
                                )}
                            /> */}
                        {/* {errors.contactNumber?.type === "required" && (
                                <div className="invalid-feedback">Contact number is required !</div>
                            )}
                            {(errors.contactNumber?.type === "minLength" || errors.contactNumber?.type === "maxLength") && (
                                <div className="invalid-feedback">Contact number is not valid !</div>
                            )} */}

                        {/* <input type="number" maxLength={10} className="form-control" placeholder="Enter phone number" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="contactNumber" {...register("contactNumber", {
                                    required: true,
                                    pattern: /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/,
                                    minLength: 10, maxLength: 12
                                })} />
                                {errors.contactNumber?.type === "pattern" && (
                                    <div className="invalid-feedback">Contact number is not valid !</div>
                                )} */}
                        {/* </div> */}

                        <div className="col-md-6 mb-3">
                            <label htmlFor="contactNumber">Contact Number</label>
                            <PhoneInput className={"form-control"}
                                {...register('contactNumber', {
                                    required: 'Contact number is required',
                                    validate: (value) => validatePhoneNumber(value),
                                })}
                                value={phoneValue}
                                onChange={handlePhoneChange}
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="US"
                                onCountryChange={handleCountryChange}
                            />
                            {errors.contactNumber && <div className="invalid-feedback">{errors.contactNumber.message}</div>}
                        </div>

                        <div className="col-md-6 mb-3">
                            <label>Email ID</label>
                            <input type="text" className="form-control" placeholder="Enter Your Email" name="contactEmailID" {...register("contactEmailID", {
                                required: true,
                                validate: (value) => { return EMAIL_PATTERN.test(value) }
                            })} />
                            {errors.contactEmailID?.type === "required" && (
                                <div className="invalid-feedback">Email is required !</div>
                            )}
                            {errors.contactEmailID && errors.contactEmailID.type === "validate" != '' ? <div className="invalid-feedback">Email is not valid !</div> : ''}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-8 mb-3">
                            <label>Address</label>
                            <input type="text" className="form-control" placeholder="Enter Your Address" name="address"
                                {...register("address", {
                                    required: false,
                                })} />
                            {errors.address?.type === "required" && (
                                <div className="invalid-feedback">Address is required !</div>

                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>City</label>
                            <input type="text" className="form-control" placeholder="Enter Your City" name="city"
                                {...register("city", {
                                    required: false,
                                })} />
                            {errors.city?.type === "required" && (
                                <div className="invalid-feedback">City is required !</div>
                            )}
                        </div>
                        <div className="col-md-4 mb-3">
                            <label>State</label>
                            <input type="text" className="form-control" placeholder="Enter Your State" name="state"
                                {...register("state", {
                                    required: false,
                                })} />
                            {errors.state?.type === "required" && (
                                <div className="invalid-feedback">State is required !</div>
                            )}
                        </div>

                        <div className="col-md-4 mb-3">
                            <label>Country</label>
                            <select className="form-control" placeholder="Select Country" name="countryID"  {...register("countryID", {
                                required: true,
                            })} >
                                <option value="" key="0">Select</option>
                                {countryResult.length > 0 && countryResult.map(country => (
                                    <option name="countryID" value={country.ID} key={country.ID}>
                                        {/* <ReactCountryFlag countryCode="US" svg style={{ width: '2em', height: '2em' }} title="US" /> */}
                                        &nbsp;&nbsp; {country.CountryName}</option>

                                ))}
                            </select>
                            {errors.countryID?.type === "required" && (
                                <div className="invalid-feedback">Country is required !</div>
                            )}
                        </div>

                        <div className="col-md-4 mb-3">
                            <label>Postal Code</label>
                            <input type="number" className="form-control" placeholder="Enter Your Postal Code" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} name="zipCode" {...register("zipCode", {
                                required: false,
                                minLength: 4,
                            })} />
                            {errors.zipCode?.type === "required" && (
                                <div className="invalid-feedback">Postal code is required !</div>
                            )}
                            {(errors.zipCode?.type === "minLength") && (
                                <div className="invalid-feedback">Postal code should be at least 5 digit !</div>
                            )}
                        </div>
                    </div>

                    <div className="form-row">

                        {!editFlag &&
                            <div className="col-md-12 mb-3">
                                <label>Username</label>
                                <input type="text" autoComplete="off" className="form-control" placeholder='Enter Username' name="username" {...register("username", {
                                    required: "Username is required!", pattern: { value: /^\S*$/, message: "Username cannot contain whitespace!" }
                                })}

                                    onKeyDown={(e) => { if (e.key === ' ' || e.key === 'Enter') { e.preventDefault(); } }} />
                                {errors.username && (
                                    <div className="invalid-feedback">{errors.username.message}</div>
                                )}
                            </div>}
                        {!editFlag && <div className="col-md-6 mb-3">
                            <label>Password</label>
                            <input type={passwordShow ? 'text' : 'password'} autoComplete="new-password" className="form-control" placeholder='Password' name="password" {...register("password", {
                                required: true,
                                // minLength: 4,
                                validate: (value) => {
                                    return PASSWORD_VALIDATE.test(value)
                                }
                            })} />
                            {
                                passwordShow ? <EyeLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} />
                            }
                            {errors.password?.type === "required" && (<div className="invalid-feedback">Password is required !</div>)}
                            {/* {(errors.password?.type === "minLength") && (<div className="invalid-feedback">Password should contain at least 6 characters !</div>)} */}
                            {errors.password && errors.password.type === "validate" != '' ? <div className="invalid-feedback">Invalid password format(Example@123) !</div> : ''}
                        </div>}


                        <div className="col-md-6 mb-3">
                            <label>Email Domain</label>
                            <select className="form-control" placeholder="Select" name="EmailForwarding"  {...register("EmailForwarding", {
                                required: true,
                            })} >
                                <option value='' disabled key='0'>Select</option>
                                {emailDomainsList.length > 0 && emailDomainsList.map(item => (
                                    <option value={item.ID} key={item.ID}>{item.Domain}</option>
                                ))}
                            </select>
                        </div>

                        {dealerLevelResult && dealerLevelResult.length > 0 && dealerLevelResult.map((data) => {
                            return (data.level === 1 && <div className="col-md-6 mt-4">
                                <div className="custom-control custom-checkbox custom-control-inline pt-2">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customCheck-t"
                                        disabled={dealerCreatetionFlag}
                                        {...register("allowSellerCreation", { value: "1", setValueAs: (val) => (val[0] === "1" ? "1" : "0") })}
                                    />
                                    <label className="custom-control-label" for="customCheck-t">Allow Dealer Creation</label>
                                </div>
                            </div>)
                        })}

                    </div>

                    <hr />

                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={formButton} style="minWidth:80px" buttonClass="btn btn-primary" loading={loading} buttonType='submit' />
                        </div>
                    </div>

                </form>
            </Modal>

            <DealerViewModel
                title={`Dealer Details `}
                responseData={dealerItem[0]}
                responsePlanDetailsHeading={dealerItem[1]}
                responseDetailsData={dealerItem[2]}
                showViewModal={showViewModal}
                handleCancel={handleCancelmodal}
            />


            <Modal
                showModal={dealerPasswordModal}
                title="Reset Password"
                onHide={handleCancel}
                width={500}

                showFooter={false}
            >
                <form onSubmit={handleSubmitReset(handleResetPassword)}>
                    <div className="form-row">
                        <div className="col-lg-12">
                            <div className="form-group mb-3" style={{ position: 'relative' }}>
                                <label htmlFor=" new password">New Password</label>
                                <input type={passwordShow ? 'text' : 'password'} className="form-control" name='newPassword' {...registerReset('newPassword', {
                                    required: true,
                                    validate: (value) => {
                                        return PASSWORD_VALIDATE.test(value)
                                    }
                                })} placeholder="New Password" />
                                <div>
                                    {
                                        passwordShow ? <EyeLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setPasswordShow((pre) => !pre)} />
                                    }
                                </div>

                                {errorsReset.newPassword && errorsReset.newPassword.type === "required" != '' ? <div className="invalid-feedback">Please input new password !</div> : ''}
                                {errorsReset.newPassword && errorsReset.newPassword.type === "validate" != '' ? <div className="invalid-feedback">Invalid password format(Example@123) !</div> : ''}
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group mb-4" style={{ position: 'relative' }}>
                                <label htmlFor="confirmnewPassword">Confirm New Password</label>
                                <input type={confirmPasswordShow ? 'text' : 'password'} className="form-control" name='confirmNewPassword' {...registerReset('confirmNewPassword', {
                                    required: true,
                                    minLength: 8,
                                    validate: (value) => {
                                        return value === newPassword;
                                    }
                                })} placeholder="Confirm New Password" />
                                <div >
                                    {
                                        confirmPasswordShow ? <EyeLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setConfirmPasswordShow((pre) => !pre)} /> : <EyeOffLineIcon size="1.1em" style={Style.eyeIcon} onClick={() => setConfirmPasswordShow((pre) => !pre)} />
                                    }
                                </div>

                                {errorsReset.confirmNewPassword && errorsReset.confirmNewPassword.type === "required" != '' ? <div className="invalid-feedback"> Please input confirm new password !</div> : ''}
                                {errorsReset.confirmNewPassword && errorsReset.confirmNewPassword.type === "minLength" != '' ? <div className="invalid-feedback">Confirm new password should contain at least 8 characters !</div> : ''}
                                {errorsReset.confirmNewPassword && errorsReset.confirmNewPassword.type === "validate" != '' ? <div className="invalid-feedback"> New password and confirm new password does not match !</div> : ''}
                            </div>
                        </div>
                    </div>

                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={formButton} style="minWidth:80px" loading={buttonLoading} buttonClass="btn btn-primary" buttonType='submit' />
                        </div>
                    </div>
                </form>
            </Modal>

        </CardLayout>
    );
}

const Style = {
    eyeIcon: {
        position: 'absolute',
        right: '10px',
        top: '40px',
        cursor: 'pointer'
    },
    modalContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: 'center'
    }
}


export default Dealer;
import React from 'react'
import CardLayout from '../../components/utils/CardLayout'
import Table from '../../components/utils/Table'
import SideButtons from '../../components/utils/SideButtons'
import moment from "moment";
import { Link } from 'react-router-dom';
import PencilFill from 'remixicon-react/PencilFillIcon';
import DeleteBin5Fill from 'remixicon-react/DeleteBin5FillIcon';
import ShutDownLineIcon from 'remixicon-react/ShutDownLineIcon'
import CheckboxCircleFill from 'remixicon-react/CheckboxCircleFillIcon';
import Popconfirm from '../../components/utils/Popover'
import Tag from '../../components/utils/Tag'
import { useState, useEffect } from 'react'
import Modal from '../../components/utils/Model';
import SearchBar from '../../components/utils/SearchBar';
import { useForm } from 'react-hook-form'
import Button from '../../components/utils/Button'
import { useDispatch, useSelector } from 'react-redux';
import CountryActions from '../../redux/country/actions'
import AssetActions from '../../redux/asset/actions'
import Alert from '../../components/utils/Alert'
import heartbeatLineGif from '../../../src/assets/images/heartbeatline.gif'
import heartbeatGif from '../../../src/assets/images/heartbeat.gif'
import { convertDateToRequiredFormatTZ } from '../../helpers/commonFunction';
import { EMAIL_PATTERN } from '../../helpers/constant';
import { isValidPhoneNumber } from 'libphonenumber-js';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';

const dateFormat = process.env.REACT_APP_DATE_FORMAT;

const TIMEZONE = process.env.REACT_APP_TIMEZONE;


const { openAddAssetModal, resetAsset, openAddLocationModal, getLocation, addLocation, updateLocation, deleteLocation, getAsset, addAsset, updateAsset, deleteAsset, updateJar } = AssetActions;

function Assets() {
    const dispatch = useDispatch();
    const { getCountry } = CountryActions;
    const { openAssetModal, openLocationModal, buttonLoading, loading, message, error, assetData } = useSelector((state) => state.asset);
    const locationData = useSelector((state) => state.asset.locationData)
    const countryResult = useSelector((state) => state.country.countryResult);
    const userDetails = useSelector((state) => state.auth.userDetails);
    const [activeTab, setActiveTab] = useState('Location');
    const [formTitle, setFormTitle] = useState('');
    const [formButton, setFormButton] = useState('');
    const [filterText, setFilterText] = useState("");
    const [editAssetId, setEditAssetId] = useState("");
    const [editFlag, setEditFlag] = useState(false);
    const [locationId, setLocationId] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [searched, setSearched] = useState(false);
    const [locationFrom, setLocationFrom] = useState({
        locationName: '',
        address: '',
        countryID: 0,
        contactPerson: '',
        contactNumber: '',
        contactEmail: '',
        createdBy: '',

    })

    const [phoneValue, setPhoneValue] = useState("");
    const [countryCode, setCountryCode] = useState("US");

    const [allAssetData, setAllAssetData] = useState({
        assetName: "",
        locationID: 0,
        bulkFlag: 0,
        bulkQuantity: "0",

    });


    useEffect(() => {
        dispatch(getCountry());
        dispatch(getLocation());
    }, []);

    useEffect(() => {
        reset(locationFrom);
    }, [locationFrom]);

    useEffect(() => {
        resetReset(allAssetData);
    }, [allAssetData]);

    const {
        register,
        handleSubmit,
        formState: { errors }, trigger,
        setValue,
        reset,
    } = useForm();

    useEffect(() => {
        if (error !== null && error !== undefined) {
            displayAlert(error, 'alert-danger');
        }
        if (message !== null && message !== undefined) {
            displayAlert(message, 'alert-success');
        }
    }, [error, message]);

    const {
        register: registerAsset,
        handleSubmit: handleSubmitAsset,
        formState: { errors: errorsReset }, reset: resetReset
    } = useForm();

    const {
        register: registerSearchAsset,
        handleSubmit: handleSearchAsset,
        formState: { errors: errorSearchAsset }, reset: resetSearchAsset
    } = useForm();

    const handleDelete = (locationId, Status) => {
        dispatch(deleteLocation(locationId, Status));
    }
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const handleAddLocation = () => {
        setLocationFrom(prevState => ({
            ...prevState,
            locationName: "",
            address: "",
            countryID: "",
            contactPerson: "",
            contactNumber: "",
            contactEmail: "",
        }));
        dispatch(openAddLocationModal());
        setFormTitle("ADD LOCATION");
        setFormButton("Submit");
        setEditFlag(false);
    };


    const handleAddAsset = () => {
        dispatch(openAddAssetModal());
        setFormTitle("ADD ASSET")
        setFormButton("Submit")
        setAllAssetData(prevState => ({
            ...prevState,
            assetName: "",
            locationID: 0,
            bulkFlag: 0,
            bulkQuantity: "0",
        }));
    };

    const handleEditAssetmodal = (id) => {
        setFormTitle('Edit ASSET');
        setFormButton('Update');
        dispatch(openAddAssetModal());
        setEditFlag(true)
        setEditAssetId(id)
        const selectedAsset = assetData && assetData.length && assetData.filter(item => item.ID === id);
        setAllAssetData(prevState => ({
            ...prevState,
            assetName: selectedAsset[0].AssetName,
            locationID: selectedAsset[0].LocationID,
            bulkFlag: selectedAsset[0].BulkFlag === 0 ? false : true,
            bulkQuantity: selectedAsset[0].BulkQuantity,
        }));
    }
    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetAsset());
    };
    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetAsset());
    }

    const handleEditLocationmodal = (id) => {
        setLocationId(id);
        setFormTitle('Edit LOCATION');
        setFormButton('Update');
        dispatch(openAddLocationModal());
        setEditFlag(true);
        const selectedLocation = locationData && locationData.length && locationData.filter(item => item.ID === id);
        setLocationFrom(prevState => ({
            ...prevState,
            locationName: selectedLocation[0].LocationName,
            address: selectedLocation[0].Address,
            countryID: selectedLocation[0].CountryID,
            contactPerson: selectedLocation[0].ContactPerson,
            contactNumber: selectedLocation[0].ContactNumber,
            contactEmail: selectedLocation[0].ContactEmail,
        }));
        setPhoneValue(selectedLocation[0].ContactNumber)
    }
    const handleUpdate = (data) => {
        data.modifiedBy = userDetails[0].ID
        data.modifiedDtTm = moment(new Date()).format(dateFormat)
        dispatch(updateLocation(data, locationId));
    }
    const handleAssetUpdate = (data) => {
        data.modifiedBy = userDetails[0].ID
        data.modifiedDtTm = moment(new Date()).format(dateFormat)
        dispatch(updateAsset(data, editAssetId));
    }

    const handleDeleteAsset = (assetId, Status) => {
        dispatch(deleteAsset(assetId, Status));
    }

    const handleJarAsset = (assetId, Status) => {
        dispatch(updateJar(assetId, Status));
    }


    const handleCancel = () => {
        setLocationFrom(prevState => ({
            ...prevState,
            locationName: "",
            address: "",
            countryID: "",
            contactPerson: "",
            contactNumber: "",
            contactEmail: "",
        }));
        setPhoneValue("");
        dispatch(resetAsset())
    }
    const onSubmit = (data) => {
        data.createdBy = userDetails[0].ID
        dispatch(addLocation(data))
    }


    const handleFilterChange = (value, data) => {
        setFilterText(value);
    }
    const handleAssetSubmit = (data) => {
        data.bulkFlag = data.bulkFlag === true ? 1 : 0;
        dispatch(addAsset(data));
    };

    const handleSearchAssetSubmit = (data) => {
        setSearched(true);
        dispatch(getAsset(data.heartbeat));

    }

    const handlePhoneChange = (value) => {
        setPhoneValue(value);
        setValue('contactNumber', value);
        trigger('contactNumber');
    };

    const handleCountryChange = (country) => {
        setPhoneValue("");
        setCountryCode(country)
    };

    const location = [{ displayName: '#', key: 'key' },
    { displayName: 'LOCATION NAME', key: 'locationName' },
    { displayName: 'ADDRESS', key: 'address' },
    { displayName: 'CONTACT DETAILS', key: 'contactDetails' },
    { displayName: 'CREATE DATE', key: 'createDate' },
    { displayName: 'STATUS', key: 'status' },
    { displayName: 'ACTION', key: 'action' },]

    const Asset = [{ displayName: '#', key: 'key' },
    { displayName: 'ASSET NAME', key: 'assetName' },
    { displayName: 'LOCATION NAME', key: 'locationName' },
    { displayName: 'BULK FLAG', key: 'bulkFlag' },
    { displayName: 'BULK QUANTITY', key: 'bulkQuantity' },
    { displayName: 'CREATE DATE', key: 'createDate' },
    { displayName: 'HEARTBEAT', key: 'heartbeat' },
    { displayName: 'TURN ON/OFF', key: 'turon' },
    { displayName: 'STATUS', key: 'status' },
    { displayName: 'ACTION', key: 'action' },]

    const filteredItemsTemplate = Array.isArray(assetData) && assetData.length > 0 ? assetData.filter(item => {
        if (typeof filterText !== 'string' || typeof item !== 'object') return false;
        // Check if any value in the object contains the filterText
        return Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
    }) : [];

    const renderAssetTable = () => {
        if (searched) {
            return (
                <Table data={dataSourceAsset} loading={loading} columns={Asset} pagination={true} />
            );
        } else {
            return null;
        };
    }

    const dataSourceAsset = filteredItemsTemplate && filteredItemsTemplate.map((item, key) => {
        return {
            key: key + 1,
            assetName: item.AssetName,
            locationName: item.LocationName,
            bulkFlag: item.BulkFlag == 1 ? 'Yes' : 'No',
            bulkQuantity: parseInt(item.BulkQuantity) > 0 ? item.BulkQuantity : '',
            heartbeat: (
                <p style={{ textAlign: "center" }}>{(item.LastInteractedTime === null || item.LastInteractedTime === 0) ? <h6><img src={heartbeatLineGif} width={100} /></h6> : <h6><img src={heartbeatGif} width={100} /></h6>}</p>
            ),
            createDate: convertDateToRequiredFormatTZ(item.CreatedDtTm),
            status: <Tag color={`${item.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={item.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: (
                <>
                    <Link to="#">
                        <span data-toggle="tooltip" data-placement="top" title="Edit">
                            <PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleEditAssetmodal(item.ID)} />
                        </span>
                    </Link>
                    {item.IsActive === 1 ? (
                        <Popconfirm
                            title="Are you sure to inactive this asset?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDeleteAsset(item.ID, 0)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Inactive">
                                    <DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>
                    ) : (

                        <Popconfirm
                            title="Are you sure to active this asset?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDeleteAsset(item.ID, 1)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Active">
                                    <CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>

                    )}
                </>),
            turon: (
                <>
                    {item.AssetRunningStatus === 1 ? (
                        <Popconfirm
                            title="Are you sure, you want to turn off the asset running status?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleJarAsset(item.ID, 0)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Turn off">
                                    <ShutDownLineIcon size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>


                    ) : (
                        <Popconfirm
                            title="Are you sure, you want to turn on the asset running status?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleJarAsset(item.ID, 1)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Turn on">
                                    <ShutDownLineIcon size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>
                    )}
                </>),
        }
    });

    const filteredItemsTemplateLocation = Array.isArray(locationData) && locationData.length > 0 ? locationData.filter(item => {
        if (typeof filterText !== 'string' || typeof item !== 'object') return false;
        // Check if any value in the object contains the filterText
        return Object.values(item).some(value => typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()));
    }) : [];


    var dataSourceLocaction = filteredItemsTemplateLocation && filteredItemsTemplateLocation.length > 0 && filteredItemsTemplateLocation.map((item, key) => {
        return {
            key: key + 1,
            locationName: item.LocationName,
            address: item.Address,
            contactDetails: (<>
                {item.ContactPerson ? <span>{item.ContactPerson}<br /></span> : ''}
                {item.ContactNumber ? <span><a href={`tel:+${item.ContactNumber}`}>{item.ContactNumber}</a><br /></span> : ''}
                <span><a href={`mailto:${item.ContactEmail}`}>{item.ContactEmail}</a></span>
            </>),
            createDate: convertDateToRequiredFormatTZ(item.CreatedDtTm),
            status: <Tag color={`${item.IsActive === 1 ? "badge-success" : "badge-danger"}`} title={item.IsActive === 1 ? 'Active' : 'Inactive'} />,
            action: (
                <>
                    <Link to="#">
                        <span data-toggle="tooltip" data-placement="top" title="Edit">
                            <PencilFill size="1.2em" style={{ color: "#888b8e", marginRight: "3px" }} onClick={() => handleEditLocationmodal(item.ID)} />
                        </span>
                    </Link>
                    {item.IsActive === 1 ? (
                        <Popconfirm
                            title="Are you sure to inactive this user?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDelete(item.ID, 0)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Inactive">
                                    <DeleteBin5Fill size="1.2em" style={{ color: "#f14336", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>
                    ) : (
                        <Popconfirm
                            title="Are you sure to active this user?"
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            onConfirm={() => handleDelete(item.ID, 1)}
                        >
                            <Link className="deleteBtn" to="# ">
                                <span data-toggle="tooltip" data-placement="top" title="Active">
                                    <CheckboxCircleFill size="1.2em" style={{ color: "#2cb549", marginRight: "3px" }} />
                                </span>
                            </Link>
                        </Popconfirm>
                    )}
                </>)
        }
    });

    return (
        <CardLayout >
            {showAlert && <Alert type={alertType} text={alertText} closeBtn={alertCancel} />}
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${activeTab === 'Location' ? 'active' : ''}`}
                        onClick={() => handleTabClick('Location')}
                    >
                        Location
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button
                        className={`nav-link ${activeTab === 'Asset' ? 'active' : ''}`}
                        onClick={() => handleTabClick('Asset')}
                    >
                        Asset
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div
                    className={`tab-pane fade show ${activeTab === 'Location' ? 'active' : ''}`}
                    id="home"
                    role="tabpanel"
                >

                    <h3>Location</h3>
                    <div className="row">
                        <div className='col-lg-6 col-md-6 od-2 mt-2'>
                            <SearchBar onFilter={handleFilterChange} filterText={filterText} /></div>
                        <div className='col-lg-6 col-md-6 text-right'>
                            <SideButtons title={`Add ${activeTab}`} buttonStyle="primary" onClick={() => handleAddLocation()} />
                        </div>
                    </div>
                    <Table data={dataSourceLocaction} loading={loading} columns={location} pagination={true} />

                </div>
                <div
                    className={`tab-pane fade ${activeTab === 'Asset' ? 'show active' : ''}`}
                    id="profile"
                    role="tabpanel"
                >
                    <div className="row">
                        <div className='col-lg-6 col-md-6 od-2 '>
                            <h3>Asset</h3> </div>
                        <div className='col-lg-6 col-md-6 text-right'>
                            <SideButtons title={`Add ${activeTab}`} buttonStyle="primary" onClick={() => handleAddAsset()} />
                        </div>
                    </div>
                    <form onSubmit={handleSearchAsset(handleSearchAssetSubmit)}>
                        <div className="row">
                            <div className="col-md-3 mb-3 p-3">
                                <label>Heartbeat</label>
                                <select className="form-control" placeholder="Select Heartbeat" name="heartbeat"  {...registerSearchAsset("heartbeat", {
                                    required: true,
                                })} >
                                    <option value="0" selected>All</option>
                                    <option value="1">Live</option>
                                    <option value="2">Dead</option>
                                </select>
                            </div>
                            <div className="col-md-4 mt-5 mb-0 pb-0 " >
                                <Button title='Search' buttonClass="btn btn-primary mb-0 mt-0 pb-2 pt-1" buttonType='submit' />
                            </div>
                        </div>
                    </form>
                    {searched ?
                        <div className="row">
                            <div className="col-md-6 od-2">
                                <SearchBar onFilter={handleFilterChange} filterText={filterText} />
                            </div>
                        </div>
                        : ""}
                    {renderAssetTable()}
                </div>
            </div>

            <Modal
                showModal={openLocationModal}
                title={formTitle}
                onHide={handleCancel}
                width={500}
                showFooter={false}
            >
                <form onSubmit={handleSubmit(editFlag ? handleUpdate : onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label>Location Name</label>
                            <input type="text" placeholder="Enter Location Name" class="form-control" name="locationName" {...register("locationName", {
                                required: true,
                            })} />
                            {errors.locationName?.type === "required" && (
                                <div class="invalid-feedback">Location name is required !</div>
                            )}
                        </div>

                        <div className="col-md-12 mb-3">
                            <label>Contact Person</label>
                            <input type="text" placeholder="Enter Contact Person" class="form-control" name="contactPerson" {...register("contactPerson", {
                            })} />
                        </div>
                        {/* <div className="col-md-12 mb-3">
                            <label>Contact Number</label>
                            <input type="text" placeholder="Enter Contact Number" class="form-control" name="contactNumber" {...register("contactNumber", {
                            })} />
                        </div> */}

                        <div className="col-md-12 mb-3">
                            <label htmlFor="contactNumber">Contact Number</label>
                            <PhoneInput className={"form-control"}
                                {...register('contactNumber', {
                                    validate: (value) => {
                                        if (!value || isValidPhoneNumber(value, countryCode)) {
                                            return true;
                                        }
                                        return "Invalid phone number for the selected country";
                                    },
                                })}
                                value={phoneValue}
                                onChange={handlePhoneChange}
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="US"
                                onCountryChange={handleCountryChange}
                            />
                            {errors.contactNumber && <div className="invalid-feedback">{errors.contactNumber.message}</div>}
                        </div>


                        <div className="col-md-12 mb-3">
                            <label>Email ID</label>
                            <input type="text" className={`form-control ${errors.contactEmail ? 'is-invalid' : ''}`} placeholder="Enter Email" name="contactEmail" {...register("contactEmail", {
                                validate: (value) => {
                                    if (!value || EMAIL_PATTERN.test(value)) {
                                        return true;
                                    }
                                    return false;
                                },
                            })}
                            />
                            {errors.contactEmail && (<div className="invalid-feedback">Email is not valid!</div>)}
                        </div>


                        <div className="col-md-12 mb-3">
                            <label>Address</label>
                            <input type="text" placeholder="Enter Address" class="form-control" name="address" {...register("address", {
                            })} />
                        </div>
                    </div>
                    <div className="col-md-12 mb-3 p-0">
                        <label>Country</label>
                        <select
                            className="form-control"
                            placeholder="Select Country"
                            name="countryID"
                            {...register("countryID", {
                                required: true,
                            })}
                        >
                            <option value="" key="0">
                                Select
                            </option>
                            {countryResult.length > 0 &&
                                countryResult.map((country) => (
                                    <option name="countryID" value={country.ID} key={country.ID}>
                                        &nbsp;&nbsp; {country.CountryName}
                                    </option>
                                ))}
                        </select>
                        {errors.countryID?.type === "required" && (
                            <div class="invalid-feedback">Country is required !</div>
                        )}
                    </div>
                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={formButton} loading={buttonLoading} style="minWidth:80px" buttonClass="btn btn-primary" buttonType='submit' />
                        </div>
                    </div>

                </form>
            </Modal>
            <Modal
                showModal={openAssetModal}
                title={formTitle}
                onHide={handleCancel}
                width={500}
                showFooter={false}
            >
                <form onSubmit={handleSubmitAsset(editFlag ? handleAssetUpdate : handleAssetSubmit)}>
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label>Asset Name</label>
                            <input type="text" placeholder="Enter Asset Name" class="form-control" name="assetName" {...registerAsset("assetName", {
                                required: true,
                            })} />
                            {errorsReset.assetName?.type === "required" && (
                                <div class="invalid-feedback">Asset name is required !</div>
                            )}
                        </div>
                        <div className="col-md-12 mb-3 p-0">
                            <label>Location</label>
                            <select className="form-control form-select" placeholder="Select Location" name="locationID"  {...registerAsset("locationID", {
                                required: true,
                            })} >
                                <option value="0" key="">Select</option>
                                {locationData && locationData.length > 0 && locationData.map(data => (
                                    <option name="locationID" value={data.ID} key={data.ID}>
                                        &nbsp;&nbsp; {data.LocationName}</option>

                                ))}
                            </select>
                            {errorsReset.assetName?.type === "required" && (
                                <div class="invalid-feedback">Location is required !</div>
                            )}
                        </div>

                        <div className="col-md-6 mb-3 p-0 ">
                            <div className='d-flex m-0 p-0 flex-column custom-control custom-checkbox custom-control-inline'>
                                <label htmlFor="bulkActivation">
                                    Bulk Activation </label>
                                <input type="checkbox" id='bulkActivation' style={{ height: "20px", width: "20px" }} {...registerAsset("bulkFlag", {
                                })} />
                            </div>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Bulk Quantity</label>
                            <input type="text" placeholder="Enter Bulk Quantity" class="form-control" name="bulkQuantity" {...registerAsset("bulkQuantity", {
                            })} />
                        </div>
                    </div>
                    <div className='text-right row'>
                        <div className="col-md-12">
                            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel}>Close</button>
                            <Button title={formButton} style="minWidth:80px" loading={buttonLoading} buttonClass="btn btn-primary" buttonType='submit' />
                        </div>
                    </div>
                </form>

            </Modal>

        </CardLayout>

    )

}

export default Assets;